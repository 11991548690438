/**
 * Created by muammer.dagidir on 10.1.2018.
 */

var tofas = tofas || {}

tofas.variables = {}

tofas.page = {
	modal: {
		itemTag: $('[data-modal]'),
		contentTag: $('[data-modal-content]'),
		closeTag: $('[data-modal-close]'),
		backdropTag: $('[data-modal-backdrop]'),
		videoTag : $('[data-modal-video]'),
		init: function() {
			tofas.page.modal.openClick();
			tofas.page.modal.closeClick();
			tofas.page.modal.backdropClick();
			tofas.page.modal.esc();
			$(window).resize(tofas.page.modal.openClick);
		},
		openClick: function() {
			tofas.page.modal.itemTag.on('click', function(){
				var attr = $(this).attr('data-modal');
				tofas.page.modal.close();
				tofas.page.modal.open(attr);
			});
		},
		closeClick: function() {
			tofas.page.modal.closeTag.click(function(){
				tofas.page.modal.close();
			});
		},
		open: function(attr) {
			tofas.page.modal.allVideoPause();
			tofas.page.modal.itemTag.removeClass('opened');
			$('[data-modal-content="' + attr + '"]').addClass('opened');
		},
		close: function(){
			tofas.page.modal.contentTag.removeClass('opened');
			tofas.page.modal.mute();
		},
		mute: function() {
			if(tofas.page.modal.videoTag.length > 0) {
				tofas.page.modal.videoTag.each(function(){
					$(this)[0].pause();
				});
			}
		},
		backdropClick: function(){
			tofas.page.modal.backdropTag.click(function(){
				tofas.page.modal.close();
			});
		},
		esc: function(){
			$(document).keydown(function(e){
				if (e.keyCode === 27) {
					tofas.page.modal.close();
				}
			});
		},
		allVideoPause: function() {
			if($('.slider-video').length > 0) {
				$('.slider-video').each(function(){
					if($(this).hasClass('video-opened')) {
						$(this).removeClass('video-opened');
						$(this).html('');
					}
				});
			}
		}
	},
	tab: {
		selectbox: $('[data-tab-select]'),
		container: $('[data-tab]'),
		title: $('[data-tab-title]'),
		content: $('[data-tab-content]'),
		image: $('[data-tab-image]'),
		init: function init() {
			tofas.page.tab.title.click(function(){
				var data = $(this).attr('data-tab-title');
				tofas.page.tab.passive();
				$(this).addClass('active');
				$('[data-tab-content="' + data + '"]').addClass('open');
				if($('[data-tab-image]').length > 0) {
					$('[data-tab-image="' + data + '"]').addClass('active');
				}
			});
		},
		passive: function() {
			tofas.page.tab.title.removeClass('active');
			tofas.page.tab.image.removeClass('active');
			tofas.page.tab.content.removeClass('open');
		},
		select: function() {
			tofas.page.tab.passive();
			if(tofas.page.tab.selectbox.length > 0) {
				$('[data-tab-content="' + tofas.page.tab.selectbox.val() + '"]').addClass('open');
				$('[data-tab-title="' + tofas.page.tab.selectbox.val() + '"]').addClass('active');
				if(tofas.page.tab.image.length > 0) {
					$('[data-tab-image="' + tofas.page.tab.selectbox.val() + '"]').addClass('active');
				}
			}
		}
	},
	accordion: {
		itemTag: $('[data-accordion-title]'),
		contentTag: $('[data-accordion-content]'),
		init: () => {
			tofas.page.accordion.click();

		},
		click: function() {
			tofas.page.accordion.itemTag.click(function(){
				tofas.page.accordion.close();
				tofas.page.accordion.open($(this));
			});
		},
		open: function(_this) {
			var attr = _this.attr('data-accordion-title');
			_this.addClass('active');
			$('[data-accordion-content="'+ attr +'"]').addClass('open');
		},
		close: function() {
			tofas.page.accordion.itemTag.removeClass('active');
			tofas.page.accordion.contentTag.removeClass('open');
		}
	},
	forms: {
		init: function() {
			tofas.page.forms.menuSelect();
			tofas.page.forms.selectbox();
			tofas.page.forms.datepicker();
			document.addEventListener('click', tofas.page.forms.closeAllSelect());
		},
		selectbox: function(){
			var x, i, j, selElmnt, a, b, c;
			x = document.getElementsByClassName('custom-select-menu');
			let d = '<span class="err"></span>';
			for (i = 0; i < x.length; i++) {
				selElmnt = x[i].getElementsByTagName('select')[0];
				a = document.createElement('DIV');
				a.setAttribute('class', 'select-selected');
				a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
				x[i].appendChild(a);
				b = document.createElement('DIV');
				b.setAttribute('class', 'select-items select-hide');
				for (j = 1; j < selElmnt.length; j++) {
					c = document.createElement('DIV');
					c.innerHTML = selElmnt.options[j].innerHTML;
					c.addEventListener('click', function(e) {
						var i, s, h;
						s = this.parentNode.parentNode.getElementsByTagName('select')[0];
						h = this.parentNode.previousSibling;
						for (i = 0; i < s.length; i++) {
								if (s.options[i].innerHTML == this.innerHTML) {
									s.selectedIndex = i;
									h.innerHTML = this.innerHTML;
									break;
								}
							}
							tofas.page.tab.select();
							tofas.page.press.select(s.value);
							h.click();
					});
					b.appendChild(c);
				}
				x[i].appendChild(b);

				let error = document.createElement('DIV');
				error.setAttribute('class', 'err');
				let msg = x[i].getAttribute('error-msg');
				error.innerHTML = msg;
				x[i].appendChild(error);
				a.addEventListener('click', function(e) {
					e.stopPropagation();
					tofas.page.forms.closeAllSelect(this);
					this.nextSibling.classList.toggle('select-hide');
					this.classList.toggle('select-arrow-active');
				});
			}
		},
		closeAllSelect: function(el) {
			var x, y, i, arrNo = [];
			x = document.getElementsByClassName('select-items');
			y = document.getElementsByClassName('select-selected');
			for (i = 0; i < y.length; i++) {
				if (el == y[i]) {
					arrNo.push(i)
				} else {
					y[i].classList.remove('select-arrow-active');
				}
			}
			for (i = 0; i < x.length; i++) {
				if (arrNo.indexOf(i)) {
					x[i].classList.add('select-hide');
				}
			}
		},
		menuSelect: function(){
			var select = $('[data-menu-select]');
			if(select.length > 0) {
				select.change(function(){
					window.location = select.val();
				});
			}
		},
		datepicker: function() {
			if($('input.date').length > 0) {
				$('input.date').focus(function(){
					$(this).attr('type','date');
					});
				$('input.date').focusout(function(){
					$(this).attr('type','text');
				});
			}
		}
	},
	home: {},
	arge: {
		init: function() {
			tofas.page.arge.carousel();
			tofas.page.arge.detailButton();
		},
		carousel: function() {
			$('[data-arge-carousel]').slick({
				dots: true,
				infinite: true,
				slidesToShow: 4,
				slidesToScroll: 4,
				arrows: true,
				prevArrow: '<button class="slick-prev" aria-label="" type="button"></button>',
				nextArrow: '<button class="slick-next" aria-label="" type="button"></button>',
				responsive: [
					{
						breakpoint: 1330,
						settings: {
						  slidesToShow: 3,
						  slidesToScroll: 3,
						  infinite: true,
						  dots: true
						}
					  },
					  {
					  breakpoint: 1024,
					  settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						infinite: true,
						dots: true,
						arrows: false
					  }
					},
					{
						breakpoint: 600,
						settings: {
							arrows: false,
							slidesToShow: 2,
							slidesToScroll: 2
					  }
					},
					{
					  breakpoint: 480,
					  settings: {
						  arrows: false,
						  slidesToShow: 1,
						  slidesToScroll: 1
						}
					}]
			});
		},
		detailButton: function() {
			$('[data-button]').click(function(){
				var ID = $(this).attr('data-button'),
						detail = $(this).attr('data-text'),
						close = $(this).attr('data-close');
				if($('[data-content="'+ ID +'"]').hasClass('opened')){
					$('[data-content="'+ ID +'"]').removeClass('opened');
					$(this).removeClass('opened');
					$(this).find('.page-button_text').html(detail);
					if($('[data-item]').length > 0) {
						$('[data-item="'+ ID +'"]').removeClass('active');
					}
				}else{
					$('[data-content="'+ ID +'"]').addClass('opened');
					$(this).addClass('opened');
					$(this).find('.page-button_text').html(close);
					if($('[data-item]').length > 0) {
						$('[data-item="'+ ID +'"]').addClass('active');
					}
				}
			});
		}
	},
	sustainability: {
		init: function() {
			tofas.page.sustainability.chart();
		},
		chart: function() {
			if($('#bar-chart').length > 0) {
				new Chart(document.getElementById('bar-chart'), {
					type: 'bar',
					data: {
						labels: [2011,2012,2013,2014,2015,2016],
						datasets: [
							{
								label: '',
								backgroundColor: '#af9572',
								data: [4.78,4.68,4.60,4.77,4.69,3.90,'']
							}
						]
					},
					options: {
						tooltips: {
							enabled: true
						 }	,
						legend: {
							display: false
						},
						title: {
							display: true,
							text: ''
						},
						scales: {
							xAxes: [{
								gridLines: {
									display:false,
									drawBorder: false
								}
							}],
							yAxes: [{
								display:false,
								gridLines: {
									display:false,
									drawBorder: false
								}
							}]
						}
					}
				});
			}

		}
	},
	press: {
		selectItem: $('[data-redirect-select]'),
		videoSliderItem: $('[data-press-video-slider]'),
		init: function() {
			tofas.page.press.videoSlider();
		},
		videoSlider: function() {
			tofas.page.press.videoSliderItem.slick({
				autoplay:false,
				infinite: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				fade: false,
				dots: false,
				adaptiveHeight: false,
				responsive: [{
					breakpoint: 992,
					settings: {
						adaptiveHeight: true,
						dots: true,
						arrows: false,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}]
			});
		},
		select: function(url) {
			if(tofas.page.press.selectItem.length > 0) {
					//console.log(url);
					window.location = url;
			}
		}
	},
	career: {
		init: function() {
			tofas.page.career.filter();
			tofas.page.career.gridbox();
		},
		filter: function() {
			$('[data-filter]').click(function(){
				var attr = $(this).attr('data-filter');
				tofas.page.career.hide(attr);
				tofas.page.career.show(attr);
				tofas.page.career.active($(this));
			});
		},
		hide: function(attr) {
			if(attr != ''){
				$('[data-filter-id]').hide();
			}
		},
		show: function(attr) {
			if(attr == ''){
				$('[data-filter-id]').show();
			}else{
				$('[data-filter-id="' + attr + '"]').show();
			}
		},
		active: function(_this){
			$('[data-filter]').removeClass('active');
			_this.addClass('active');
		},
		gridbox: function() {
			if($('.grid').length > 0) {
				$('.grid').isotope({
					itemSelector: '.grid-item',
					percentPosition: true,
					masonry: {
						columnWidth: '.grid-sizer'
					}
				});
			}
		}
	},
	timeline: {
		init: function() {
			tofas.page.timeline.calc();
			$(window).on('resize', function () {
				tofas.page.timeline.calc();
			});
		},
		calc: function() {
			//var data =  [],
			//selector = [];
			var el = $('[data-timeline-content]');
			el.each(function(index){
				var subItemCount = $(this).find('[data-timeline-box]').length;
				var subItemWidth = $(this).find('[data-timeline-box]').width();
				var contentWidth = (subItemCount * subItemWidth) + (subItemCount * 50);
				if (window.matchMedia('(min-width: 991px)').matches) {
					$(this).width(contentWidth);
				}else{
					$(this).width('100%');
				}
			});
		}
	},
	contact: {
		init: function() {
			tofas.page.contact.map();
		},
		map: function() {
			var locations = [
				['Genel Müdürlük', 41.0696625,29.010739, 4],
				['Fabrika', 40.2613941,29.0713591, 5]
			];
			if(document.getElementById('map')){
				var map = new google.maps.Map(document.getElementById('map'), {
					zoom: 7,
					center: new google.maps.LatLng(41.0696625,29.010739),
					disableDefaultUI: true,
					styles:[
									{
										'featureType': 'administrative',
										'elementType': 'labels',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'gamma': '1.82'
												}
										]
								},
								{
										'featureType': 'administrative',
										'elementType': 'labels.text.fill',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'gamma': '1.96'
												},
												{
														'lightness': '-9'
												}
										]
								},
								{
										'featureType': 'administrative',
										'elementType': 'labels.text.stroke',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'landscape',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'lightness': '25'
												},
												{
														'gamma': '1.00'
												},
												{
														'saturation': '-100'
												}
										]
								},
								{
										'featureType': 'poi.business',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'poi.park',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'road',
										'elementType': 'geometry.stroke',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'road',
										'elementType': 'labels.icon',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'road.highway',
										'elementType': 'geometry',
										'stylers': [
												{
														'hue': '#ffaa00'
												},
												{
														'saturation': '-43'
												},
												{
														'visibility': 'on'
												}
										]
								},
								{
										'featureType': 'road.highway',
										'elementType': 'geometry.stroke',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'road.highway',
										'elementType': 'labels',
										'stylers': [
												{
														'visibility': 'simplified'
												},
												{
														'hue': '#ffaa00'
												},
												{
														'saturation': '-70'
												}
										]
								},
								{
										'featureType': 'road.highway.controlled_access',
										'elementType': 'labels',
										'stylers': [
												{
														'visibility': 'on'
												}
										]
								},
								{
										'featureType': 'road.arterial',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'saturation': '-100'
												},
												{
														'lightness': '30'
												}
										]
								},
								{
										'featureType': 'road.local',
										'elementType': 'all',
										'stylers': [
												{
														'saturation': '-100'
												},
												{
														'lightness': '40'
												},
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'transit.station.airport',
										'elementType': 'geometry.fill',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'gamma': '0.80'
												}
										]
								},
								{
										'featureType': 'water',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								}
					]
				});
				var marker, i;
				for (i = 0; i < locations.length; i++) {  
					marker = new google.maps.Marker({
						position: new google.maps.LatLng(locations[i][1], locations[i][2]),
						map: map,
						icon: '/images/mapmarker.png'
					});
				}
			}			
		},
		map: function() {
			var locations = [
				['Fabrika', 40.2613941,29.0713591, 5]
			];
			if(document.getElementById('registermap')){
				var map = new google.maps.Map(document.getElementById('registermap'), {
					zoom: 7,
					center: new google.maps.LatLng(40.2613941,29.0713591),
					disableDefaultUI: true,
					styles:[
									{
										'featureType': 'administrative',
										'elementType': 'labels',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'gamma': '1.82'
												}
										]
								},
								{
										'featureType': 'administrative',
										'elementType': 'labels.text.fill',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'gamma': '1.96'
												},
												{
														'lightness': '-9'
												}
										]
								},
								{
										'featureType': 'administrative',
										'elementType': 'labels.text.stroke',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'landscape',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'lightness': '25'
												},
												{
														'gamma': '1.00'
												},
												{
														'saturation': '-100'
												}
										]
								},
								{
										'featureType': 'poi.business',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'poi.park',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'road',
										'elementType': 'geometry.stroke',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'road',
										'elementType': 'labels.icon',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'road.highway',
										'elementType': 'geometry',
										'stylers': [
												{
														'hue': '#ffaa00'
												},
												{
														'saturation': '-43'
												},
												{
														'visibility': 'on'
												}
										]
								},
								{
										'featureType': 'road.highway',
										'elementType': 'geometry.stroke',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'road.highway',
										'elementType': 'labels',
										'stylers': [
												{
														'visibility': 'simplified'
												},
												{
														'hue': '#ffaa00'
												},
												{
														'saturation': '-70'
												}
										]
								},
								{
										'featureType': 'road.highway.controlled_access',
										'elementType': 'labels',
										'stylers': [
												{
														'visibility': 'on'
												}
										]
								},
								{
										'featureType': 'road.arterial',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'saturation': '-100'
												},
												{
														'lightness': '30'
												}
										]
								},
								{
										'featureType': 'road.local',
										'elementType': 'all',
										'stylers': [
												{
														'saturation': '-100'
												},
												{
														'lightness': '40'
												},
												{
														'visibility': 'off'
												}
										]
								},
								{
										'featureType': 'transit.station.airport',
										'elementType': 'geometry.fill',
										'stylers': [
												{
														'visibility': 'on'
												},
												{
														'gamma': '0.80'
												}
										]
								},
								{
										'featureType': 'water',
										'elementType': 'all',
										'stylers': [
												{
														'visibility': 'off'
												}
										]
								}
					]
				});
				var marker, i;
				for (i = 0; i < locations.length; i++) {  
					marker = new google.maps.Marker({
						position: new google.maps.LatLng(locations[i][1], locations[i][2]),
						map: map,
						icon: '/images/mapmarker.png'
					});
				}
			}			
		}
	}
}



/* init */


tofas.page.modal.init();
tofas.page.tab.init();
tofas.page.accordion.init();
tofas.page.forms.init();
tofas.page.arge.init();
tofas.page.sustainability.init(); 
tofas.page.press.init();
tofas.page.career.init();
tofas.page.timeline.init();
tofas.page.contact.init();

$(function() {	
	$('#datepicker').datepicker({
		minDate: new Date()
	});
});